const currentYear = new Date().getFullYear();

// Foco sobre la ventana para disponer del evento blur
window.focus();


// Subscripción al clic en el documento
// Si el elemento clicado contiene el atributo data-trigger
// con valor igual a 'help' hace clic en el botón del widget
window.addEventListener('click', function (event) {
  if (event.target.hasAttribute("data-trigger")) {
    if (event.target.getAttribute("data-trigger") == 'help') {
      changeHelpLabels(100);
      const helpIframe = document.getElementById("jsd-widget");
      const helpButton = helpIframe.contentWindow.document.getElementById('help-button');
      if (helpButton) {
        helpButton.click();
      } else {
        closeButton = helpIframe.contentWindow.document.querySelector('.close-icon.fit');
        if (closeButton) {
          closeButton.click();
        }
      }
    }
  }
});

// Subscripción a la perdida del foco en el documento
window.addEventListener('blur', function (event) {
  changeHelpLabels(100);
  const helpIframe = document.getElementById("jsd-widget");
  // Comprobamos si el widget está presente
  if (helpIframe) {
    widgetBody = helpIframe.contentWindow.document.querySelector('body');
    if (widgetBody) {
      widgetBody.addEventListener('click', function (event) {
        if (event.target.getAttribute("type") == 'submit') {
          changeHelpLabels(750);
        } else if (event.target.getAttribute("id") == 'help-button' || event.target.getAttribute("class") == 'help-button-contents') {
          changeHelpLabels(100);
        } else if (event.target.getAttribute("data-link")) {
          window.location = 'https://puntoapuntorenault.com/como-conseguir-puntos/faqs';
        }
      });
    }
  }
});

const customFaqsHtml = '<style>.faqs-button{cursor: pointer;padding:8px 16px;background-color: #4B9BF5 !important; color: #FFFFFF !important; margin: 0 0 8px;border-radius:.25rem;font-size:14px !important; text-decoration: none !important; display:block; text-align: center; font-weight: bold }</style><a data-link="https://puntoapuntorenault.com/como-conseguir-puntos/faqs" class="faqs-button">Consulta nuestras preguntas frecuentes</a>';

// Función que cambia los textos del widget
function changeHelpLabels(timeOut) {
  setTimeout(() => {
    const helpIframe = document.getElementById("jsd-widget");
    if (helpIframe) {
      helpForm = helpIframe.contentWindow.document.querySelector('#help-form');
      if (helpForm) {
        helpForm.style.height = '375px';
        helpForm.style.maxHeight = '375px';
      }
      subjectLabel = helpIframe.contentWindow.document.querySelector('[for="summary"]');
      if (subjectLabel) {
        subjectLabel.innerHTML = 'Asunto';
      }
      bodyLabel = helpIframe.contentWindow.document.querySelector('[for="description"]');
      if (bodyLabel) {
        bodyLabel.innerHTML = 'Consulta';
      }
      emailLabel = helpIframe.contentWindow.document.querySelector('[for="email"]');
      if (emailLabel) {
        emailLabel.innerHTML = 'e-mail de contacto';
      }
      sendButton = helpIframe.contentWindow.document.getElementById('submit-button');
      if (sendButton) {
        sendButton.innerHTML = 'Enviar';
      }
      footer = helpIframe.contentWindow.document.querySelector('.powered-by');
      if (footer) {
        footer.innerHTML = customFaqsHtml + '<strong style="color: #978B7F">Punto a Punto © Renault Group ' + currentYear + '</strong>';
        footer.style.display = 'block';
        footer.style.height = 'auto';
        footer.style.padding = '1rem';
        footer.style.textAlign = 'center';
      }
      thanks = helpIframe.contentWindow.document.querySelector('#confirmation-container h3');
      if (thanks) {
        thanks.innerHTML = '¡Gracias!';
      }
      closeButton = helpIframe.contentWindow.document.querySelector('.close-button-container button');
      if (closeButton) {
        closeButton.innerHTML = 'Cerrar';
      }
    }
  }, timeOut);
}
