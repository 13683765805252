// Subscripción al clic en el documento
// Si el elemento clicado contiene el atributo data-trigger
// con valor igual a 'help' hace clic en el botón del widget
window.addEventListener('click', function (event) {
  if (event.target.hasAttribute("data-trigger")) {
    if (event.target.getAttribute("data-trigger") == 'cookies-config') {
      CookieConsent.showPreferences();
    }
  }
});
